export default function GrupoFamiliar(props) {
        
    function agregar_miembro() {
        props.setEditarG(true)
    }
    function borrar(id) {
        const todos = props.socio.grupo_fam_lis.split(',').filter(a => parseInt(a)!== id)
        props.setSocio({...props.socio, grupo_fam_lis: todos.join(',')})
        props.setRec_socs(! props.rec_socs)
    }
    function cabeza() {
        if (props.socio.grupo_fam_cab) 
            props.setSocio({...props.socio, grupo_fam_lis: '', grupo_fam_cab: false})
        else
            props.setSocio({...props.socio, grupo_fam_cab: true})
    }

    return (<>
    
    <div className="flex flex-col p-2 ">
        <label className="text-lg font-semibold">Grupo Familiar</label>
        {props.socio.grupo_fam_lis==='' || props.socio.grupo_fam_cab ?
        <div>
            <input 
                type="checkbox" 
                checked={props.socio.grupo_fam_cab}
                onChange={() => cabeza()}
            />
            <label className="ml-10">¿Es cabeza de un grupo familiar?</label>
        </div>
        :
        ''
        }
        {props.socio.grupo_fam_cab ?
            props.socio.grupo_fam_lis.split(',').map(a => {
            const data = props.socios.filter(e => e.id === parseInt(a))
            if (data.length) {
                return (<>
                    <div className="fle flex-row">
                        <label 
                            className="text-lg text-red-600 mr-10"
                            onClick={() => borrar(data[0].id)}
                        >X
                        </label>
                        <label key={data.id}>{data[0].apellido + ' ' + data[0].nombre}</label>
                    </div>
                    </>)
                }
        })
        :
        ''
        }
        {!props.socio.grupo_fam_cab && props.socio.grupo_fam_lis !== ''  ?
            props.socio.grupo_fam_lis.split(',').map(a => {
                const data = props.socios.filter(e => e.id === parseInt(a))
                if (data.length) {
                    return (<>
                        <div className="fle flex-row">
                            <label key={data.id}>{data[0].apellido + ' ' + data[0].nombre}</label>
                        </div>
                    </>)
                }
            })
        :
        ''
        }
        {props.socio.grupo_fam_cab ?
            <label 
                className="text-2xl text-blue-700"
                onClick={() => agregar_miembro()}
            >
            +
            </label>
        :
            ''
        }  
    </div>
    </>)
}