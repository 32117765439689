import { isDate } from 'date-fns'
import { useState } from 'react'

export default function BuscarSocioCob(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')
    
    function traer_socio (id) {
        if(!id) return
        fetch(props.back + 'admin/traer_socio/id/' + id, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + props.token,
              'Content-Type': 'application/json' 
            }
          })
         .then (response => response.json())
         .then (json => {
            props.setDatos_socio(json) 
            elegir(json)
        })
    }

    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

    function elegir(a) {
        if (!a.grupo_fam_cab && a.grupo_fam_lis!== '') {
            traer_socio(parseInt(a.grupo_fam_lis))
        } 
        if (a.grupo_fam_cab) {
            if (a.grupo_fam_lis==='') return
            const cuales = a.grupo_fam_lis.split(',')
            const ids = cuales.map (e => {return parseInt(e)}) 
            traer_familia(ids)
        }
        if (!a.grupo_fam_cab && a.grupo_fam_lis==='') {
            props.setFamilia([])
        }
    }

    function traer_familia(ids) {
        const data = {ids: ids}
        fetch (props.back + 'admin/traer_familia/', {
            body : JSON.stringify(data), 
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + props.token,
              'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => props.setFamilia(json))
    }

    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1 w-full">
            <div>
                Apellido
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={dni}
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[29rem]">
        {props.socios.map(a => {
            if (a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) 
                return  (<>
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={e => traer_socio(a.id)}
                 >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                 </li>
                 </>)
        })}
        </ul>
    </>)
}