import {useState, useEffect} from 'react'
import ControlesSocio from './controles_socio.js'

export default function Importes(props) {
    const [importes, setImportes] = useState([])
    const [discip, setDiscip] = useState([])
    

    function traer_valores () {
        fetch (props.back + 'admin/traer_importes', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            const f = json.map(a => {
                return {grupo: a.grupo, valor: a._avg.valor}
            })
            setDiscip(f)
        })
        fetch(props.back + 'admin/traer_importes_cuentas' , {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            const f = json.map(a => {
                return {cate: a.cate, valor: a.valor, id: a.id}
            })
            setImportes(f)
        })
    }

    useEffect( () => {
        traer_valores()
    },[])
    
    
    function validar_dep(e, cual) {
        if (!isNaN(parseInt(e))) {
            const f = discip.map (a => {
                return a.grupo === cual ? {grupo: cual , valor: e} : a
            })
            setDiscip(f)
        }
    }
    function validar(e,cual) {
        if (!isNaN(parseInt(e))) {
            const t= importes.map (a => {
                return a.cate === cual ? {cate: cual, valor: e, id: a.id} : a 
            })
            setImportes(t)
        } else {
            const t= importes.map (a => {
                return a.cate === cual ? {cate: cual, valor: 0, id: a.id} : a 
            })
            setImportes(t)
        }
    }

    function guardar_cambios() {
        discip.map(a => {
            fetch (props.back + 'admin/update_actividades', {
                body : JSON.stringify(a),
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {})
         })
         importes.map (a => {
         fetch (props.back + 'admin/update_importes', {
            body : JSON.stringify(a),
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => props.setElegido(0))
        })
    }

    return (<>
        <div className="grid grid-cols-3 max-h-2/3 w-full">
            <div className="py-3 px-2 w-full text-center border-r-2 border-gray-600">
                <label className="text-lg font-semibold">
                    Valor Cuota Socio
                </label>
                {importes.map(a => {
                    return (<>
                        <div className="text-lg py-3 grid grid-cols-2">
                            <label className="px-2">{a.cate}</label>
                            <input 
                                className="outline-0 border-b-2 border-gray-600 focus:border-red-400 w-32 text-right"
                                type="text" 
                                value={a.valor}
                                onChange={(e => validar(e.target.value, a.cate))}
                            />
                        </div>
                    </>)}
                )}
                <ControlesSocio guardar={guardar_cambios} setElegido={props.setElegido}/>               
            </div>
          
            <div className="py-3 px-2 w-full text-center border-r-2 border-gray-600">
                <label className="text-lg font-semibold">
                    Valor Cuota Deportiva
                </label>
                <div className="overflow-y-auto h-4/5">
                {discip.map( a => {return (<>
                    <div className="text-lg py-3 grid grid-cols-2">
                        <label className="px-2">{a.grupo}</label>
                        <input 
                            className="outline-0 border-b-2 border-gray-600 focus:border-red-400 w-32 text-right"
                            type="text"
                            value={a.valor}
                            onChange={(e => validar_dep(e.target.value, a.grupo))}
                        />
                    </div>
                    </>)
                })}
                </div>
            </div>
        </div>
        </>)
}