import {useEffect} from 'react'

export default function Totales (props) {
    const formato = "bg-green-600 text-white p-2 rounded-xxl mx-5"
    const formato_v = "bg-red-600 text-white p-2 rounded-xxl mx-5"
    const formato_d = "bg-gray-600 text-white p-2 rounded-xxl mx-5"

    useEffect (() =>{
        props.setTotal(props.total_soc + props.total_dep + props.socio.saldo_ant)
        props.setEfe(props.total_soc + props.total_dep + props.socio.saldo_ant)
        props.setTra(0)
    },[props.total_soc, props.total_dep])

    function equiparar(que,cuanto) {
        if (que==='e') {
            props.setEfe(cuanto)
            props.setTra(props.total-cuanto)
        } else {
            props.setTra(cuanto)
            props.setEfe(props.total-cuanto)
        }
    }

    return (<>
    <div className="grid grid-cols-4 gap-8 mx-40 items-center my-5">
        <div className="flex flex-row justify-between">
            <label className="px-2 text-2xl justify-self-start"> TOTAL </label>
            <label className="px-2 text-2xl justify-self-end">{props.total}</label>
        </div>
        <div className="flex flex-col justify-center text-lg">
            <div className="flex flex-row justify-between items-center my-1">
            <label className="mr-5">Efectivo</label>
                <input 
                    className="w-20 text-right"
                    type="text"
                    value={props.efe}
                    onChange={e => equiparar('e',e.target.value)}
                />
            </div>
            <div className="flex flex-row justify-between items-center my-1">
            <label className="mr-5">Transferencia</label>
                <input 
                    className="w-20 text-right"
                    type="text"
                    value={props.tra}
                    onChange={e => equiparar('t',e.target.value)}
                />
            </div>
        </div>
        <button 
            className={props.una_vez || !props.total ? formato_d : formato}
            disabled={props.una_vez || !props.total}
            onClick={e => props.cobrar()}    
        >
        COBRAR
        </button>
        <button 
            className={formato_v}
            disabled={props.una_vez}
            onClick={e => props.setDatos_socio({id: 0})}    
        >
        VOLVER
        </button>
    </div>
    </>)
}