import {useState, useEffect} from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'

export default function LisCateg (props) {
    const [seleccionados, setSeleccionados] = useState(0)
    const [data, setdata] = useState('')
    const [resultado, setResultado] = useState([])
    const [socios, setSocios] = useState([])
    
    var cont=0

    function guardar() {
        const worksheet = XLSX.utils.json_to_sheet(resultado);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const fecha = new Date()
        const a = fecha.getFullYear().toString()
        const b = (fecha.getMonth() + 1).toString().padStart(2,'0')
        const c = fecha.getDate().toString().padStart(2,'0')
        const d = a + '-' + b +  '-' + c
        const hora = fecha.getHours().toString().padStart(2,'0')
        const minutos = fecha.getMinutes().toString().padStart(2,'0')
        const segundos = fecha.getSeconds().toString().padStart(2,'0')
        const timer = hora + '-' + minutos + '-' + segundos
        XLSX.writeFile(workbook, 'listados_socios_' + d + ' ' + timer + '.xlsx')
    }

    useEffect (() => {
        function traer_socios() {
            if(props.token!=='') {
                fetch(props.back + 'admin/traer_socios', {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer ' + props.token,
                      'Content-Type': 'application/json' 
                    }
                 })
                .then (response => response.json())
                .then (json => {
                    traer_cuotas(json)
                })
             }
        }
        traer_socios()
    },[])

    useEffect (() => {
        setdata(elegir())
        setSeleccionados(cont)
    }, [socios])

    function traer_cuotas(leido) {
        fetch(props.back + 'admin/traer_cuotas', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json =>  {
            const data = leido.map(a => {
                const t = json.filter(e =>  parseInt(e.id) === parseInt(a.ultima_s))
                const b =  t.length ? t[0].descrip : ' '
                const u = json.filter(e =>  parseInt(e.id) === parseInt(a.ultima_d))
                const c =  u.length ? u[0].descrip : ' '
                return {...a, ultima_s: b, ultima_d: c}
            })
            setSocios(data)
        })
    }
   
    function activi(id) {
        const t = socios.filter(a => a.id === id)
        if (t[0].actividad.length) {
            const zzz = t[0].actividad.map(a => {
                const act = props.actividades.filter(e => e.id ===a.actividadId)
                return {nombre: act[0].grupo}
            })
            return  zzz
        } else
            return [{nombre: 'Sin Actividad'}]
    }

    function elegir () {
        if(!socios.length) return
        cont=0
        var r=[]
        var van = 0
        console.log(props.act)
        const result = socios.map(a =>  {
            const t = activi(a.id)
                const w = t.map(e => {
                    if (e.nombre===props.act) {
                        const nacido = a.nacimiento.substring(0,4)
                        r.push({apellido: a.apellido, nombre: a.nombre, nacido: nacido, documento: a.documento, social: a.ultima_s, deportiva: a.ultima_d })
                        cont++
                        return (<>
                            <div className="grid grid-cols-8" key={a.id}>
                            <label>{a.apellido}</label>
                            <label>{a.nombre}</label>
                            <label>{nacido}</label>
                            <label>{a.documento}</label>
                            <label className="col-span-2">{a.email}</label>
                            <label className="text-right">{a.ultima_s}</label>
                            <label className="text-right">{a.ultima_d}</label>
                            </div>
                            </>)
                    }
                    })
                    return w
            })
            console.log(van)
        setResultado(r)
        return result
    }

    return (<>
        <div className="px-2 flex flex-col w-full h-3/4">
            <div className="grid grid-cols-8 my-2 w-full">
                <label>Apellido</label>
                <label>Nombre</label>
                <label>Nacido</label>
                <label>Documento</label>
                <label className="col-span-2">E - mail</label>
                <label className="text-center">Cuota Social</label>
                <label className="text-center">Cuota Deportiva</label>
                
            </div>
            <div className="px-2 h-full overflow-y-auto mt-3 border-b-2 border-gray-600">
            {data}
           
           </div>   
           
        </div>
        <div className="w-full text-lg mt-2 text-center w-1/3 flex flex-row items-center justify-evenly">
        <div className="flex flex-row w-1/6 justify-between">
            <label>Seleccionados: </label>
            <label>{seleccionados}</label>
        </div>
        <button 
            className="text-lg text-white bg-green-600 p-3 rounded-xl"
            onClick={() => guardar()}
        >Descargar</button>
        </div>
    </>)
}