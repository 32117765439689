import { useState, useEffect } from 'react'
import Login from './login.js'
import Barra from './barra.js'
import Menu from './menu.js'
import Ingreso from './Ingreso/ingreso.js'
import Listado_profes from './Listados/listado_categ_profes.js'


export default function  App () {
  
  const [socios, setSocios] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [token, setToken] = useState('')
  const [datos_u, setDatos_u] = useState({rol: 0})
  const [actividades, setActividades] = useState([])
  const [recargar, setRecargar] = useState(0)
  const [rec_socs, setRec_socs] = useState()
  const [importes, setImportes] = useState([])
  const [imp_social, setImp_social] = useState([])
  const back = 'https://clubrionegro.ar:8001/'

  useEffect (() => {
    function traer_socios() {
      if(token!=='') {
        fetch(back + 'admin/traer_todos_socios', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json' 
          }
        })
       .then (response => response.json())
       .then (json => setSocios(json))
      }
    }
    traer_socios()
  },[token, rec_socs])

  useEffect (() =>{
    function traer_actividades () {
      if(token!=='') {
        fetch (back + 'admin/traer_actividades', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json' 
            }
            })
        .then (response => response.json())
        .then (json => setActividades(json))
        }
    }
    
    function traer_importes_cuotas() {
      if(token!=='') {
        fetch(back + 'admin/traer_importes_cuentas', {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json' 
            }
         })
        .then (response => response.json())
        .then (json => setImportes(json))
      }
    }
    function traer_importes_cuotas_sociales() {
      if(token!=='') {
        fetch(back + 'admin/traer_importes', {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json' 
            }
         })
        .then (response => response.json())
        .then (json => setImp_social(json))
      }
    }
    traer_actividades()
    traer_importes_cuotas()
    traer_importes_cuotas_sociales()
  },[token, recargar])

  return (<>
    {! datos_u.rol ?
    <div className="w-screen h-screen bg-black overflow-hidden" id="esconder">
      <Login  back={back} esconder="esconder" setIsOpen={setIsOpen} isOpen={isOpen} setToken={setToken} setDatos_u={setDatos_u} />
      <Barra setIsOpen={setIsOpen}/>
      <div className="flex justify-center items-center h-full">
        <img src="logo_clubrionegro.jpeg" alt="" width="400"/>
      </div>
    </div>
    :
    ''
    }   
    {datos_u.rol ?
      <div className="w-screen h-screen bg-zinc-300 overflow-hidden">
         <Barra datos_u={datos_u}/>
         
         {datos_u.rol > 1 && datos_u.rol < 4?
            <Menu back={back} token={token} actividades={actividades} setActividades={setActividades} datos_u={datos_u} socios={socios} rec_socs={rec_socs} setRec_socs={setRec_socs} importes={importes} imp_social={imp_social}/>
          : ''}
          {datos_u.rol === 1 ?
            <Ingreso back={back} token={token} actividades={actividades} socios={socios} /> 
          : ''}
          {datos_u.rol === 4 ? 
            <Listado_profes back={back} token={token} actividades={actividades} socios={socios} act={datos_u.apellido}/>
          : ''}
      </div>
    :
    ''
    }
    
  </>)
  }
