import { useState } from 'react'

export default function BuscarSocio(props) {
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')
    const formato = props.h !==undefined ? props.h +  " overflow-y-auto cursor-pointer" : "h-[500px] overflow-y-auto cursor-pointer"
    
    function traer_socio (id) {
        if(!id) return
        fetch(props.back + 'admin/traer_socio/id/' + id, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + props.token,
              'Content-Type': 'application/json' 
            }
          })
         .then (response => response.json())
         .then (json => props.setDatos_socio(json))
    }

    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

   
    return (<>
        <div className="grid grid-cols-3 justify-items-center py-1 w-full">
            <div>
                Apellido
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}    
                    key="1234"               
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}                 
                    key="12345"
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-20 p-1 ml-5"
                    type="text"
                    value={dni}
                    key="123456"
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className={formato}>
        {props.cual!=='ref' ? 
        props.socios.map(a => {
            return (<>
            {(a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) ?
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={() => traer_socio(a.id)}
                >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                </li>
            : '' }
            </>)
            })
            : 
            props.socios.map(a => {
                return (<>
                {(a.nombre_ref.toLowerCase().includes((nombre).toLowerCase()) && a.apellido_ref.toLowerCase().includes((apellido).toLowerCase()) && a.documento_ref.includes((dni))) ?
                    <li 
                        key={a.id} 
                        className="grid grid-cols-3 px-2 text-sm"
                        onClick={() => traer_socio(a.id)}
                    >
                    <div>{a.apellido_ref}</div>
                    <div>{a.nombre_ref}</div>
                    <div>{a.documento_ref}</div>
                    </li>
                : '' }
                </>)
                })

        }
        </ul>
    </>)
}