import BuscarSocio from '../buscar_socio.js'
import MostrarIngreso from './mostrar_ingreso.js'
import BuscarAutorizado from './buscar_autorizado_ing.js'
import {useState} from 'react'
export default function Ingreso(props) {
    const [datos_socio, setDatos_socio] = useState({id: 0})
    
    return (<>
    <div className="w-full">
        <div className="flex flex-row">
            <div className="border-r-2 border-r-2 border-gray-600 px-3 w-1/2">
                <p className="text-center text-lg">Socio</p>
                <BuscarSocio back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} setDatos_socio={setDatos_socio} h={'h-48'}/>
            </div>
            <div className="px-3 w-1/2">
                <p className="text-center text-lg">Autorizado</p>
                <BuscarSocio  back={props.back} token={props.token} actividades={props.actividades} socios={props.socios} setDatos_socio={setDatos_socio} h={'h-48'} cual={'ref'}/>
            </div>
        </div>
    </div>
    <div className="w-full px-3"> 
        {datos_socio.id ?
            <MostrarIngreso back={props.back} token={props.token} socio={datos_socio} actividades={props.actividades} />
        : ''
        }
    </div>
    </>)
}