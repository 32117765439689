import {useState, useEffect} from 'react'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx/xlsx.mjs'

export default function LisCateg (props) {
    const [categoria, setCategoria] = useState('0')
    const [actividad, setActividad] = useState('0')
    const [genero, setGenero] = useState('0')
    const [seleccionados, setSeleccionados] = useState(0)
    const [data, setdata] = useState('')
    const [resultado, setResultado] = useState([])
    
    var cont=0

    function guardar() {
        const worksheet = XLSX.utils.json_to_sheet(resultado);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const fecha = new Date()
        const a = fecha.getFullYear().toString()
        const b = (fecha.getMonth() + 1).toString().padStart(2,'0')
        const c = fecha.getDate().toString().padStart(2,'0')
        const d = a + '-' + b +  '-' + c
        const hora = fecha.getHours().toString().padStart(2,'0')
        const minutos = fecha.getMinutes().toString().padStart(2,'0')
        const segundos = fecha.getSeconds().toString().padStart(2,'0')
        const timer = hora + '-' + minutos + '-' + segundos
        XLSX.writeFile(workbook, 'listados_socios_' + d + ' ' + timer + '.xlsx')
    }

    useEffect (() => {
        setdata(elegir())
        setSeleccionados(cont)
    }, [categoria, actividad, genero])
   
    const hoy = dayjs(new Date())
    function cate (id) {
        const t = props.socios.filter(a => a.id === id)
        const nac = dayjs(t[0].nacimiento)
        const ed = hoy.diff(nac, 'year')
        
        const alta_soc = dayjs(t[0].alta_sistema)
        const ant = hoy.diff(alta_soc, 'year')
        var cual ='Menor'
        if (ed>=5 && ed<18) cual= 'Cadete'
        if (ed>=18 && ed<60 && t[0].sexo==='F') cual= 'Dama'
        if (ed>=18 && ed<65 && t[0].sexo==='M') cual= 'Activo'
        if (ed>=48 && ant>=30) cual= 'Vitalicio'
        if ((ed>=60 && ant<30 && t[0].sexo==='F') || (ed>=65 && ant<30 && t[0].sexo==='M')) cual='Jubilado'
        return cual
    }

    function activi(id) {
        const t = props.socios.filter(a => a.id === id)
        if (t[0].actividad.length) {
            const zzz = t[0].actividad.map(a => {
                const act = props.actividades.filter(e => e.id ===a.actividadId)
                return {nombre: act[0].nombre}
            })
            return  zzz
        } else
            return [{nombre: 'Sin Actividad'}]
    }

    function elegir () {
        if(!props.socios.length) return
        cont=0
        var r=[]
        const result = props.socios.map(a =>  {
        if (genero===a.sexo || genero==='0') {
            const z = cate(a.id)
            const t = activi(a.id)
            if (categoria===z || categoria==='0') {
                if(t !== undefined)  {   
                    const w = t.map(e => {
                        if (actividad===e.nombre || actividad==='0') {
                            r.push({apellido: a.apellido, nombre: a.nombre, categoria: z, actividad: e.nombre, sexo: a.sexo})
                            cont++
                            return (<>
                                <div className="grid grid-cols-5" key={a.id}>
                                <label>{a.apellido}</label>
                                <label>{a.nombre}</label>
                                <label className="text-right">{z}</label>
                                <label className="text-right">{e.nombre}</label>
                                <label className="text-center">{a.sexo}</label>
                            </div>
                            </>)
                        }
                    })
                    return w
                }
            }}
        })
        setResultado(r)
        return result
    }

    return (<>
        <div className="px-2 flex flex-col justify-center w-full h-2/3">
            <div className="grid grid-cols-2 my-2">
                <label>&nbsp;</label>
                <div className="grid grid-cols-3">
                    <div className="flex flex-col justify-center px-10 text-lg">
                        <label>Categoría</label>
                        <select
                        className="text-lg w-full"
                        value={categoria}
                        onChange={e => setCategoria(e.target.value)}
                        >
                            <option value="0">Todos</option>
                            <option value="Menor">Menor</option>
                            <option value="Cadete">Cadete</option>
                            <option value="Dama">Dama</option>
                            <option value="Activo">Activo</option>
                            <option value="Jubilado">Jubilado</option>
                            <option value="Vitalicio">Vitalicio</option>
                        </select>
                    </div>
                    <div className="flex flex-col justify-center px-10 text-lg">
                        <label>Actividad</label>
                        <select
                            className="text-lg w-full"
                            value={actividad}
                            onChange={e => setActividad(e.target.value)}
                        >
                            <option value="0">Todas</option>
                            <option value="Sin Actividad">Sin Actividad</option>
                            {props.actividades.map(a => {
                                return (<option value={a.nombre}>{a.nombre}</option>)
                            })
                            }
                        </select>
                    </div>
                    <div className="flex flex-col justify-center px-10 text-lg">
                        <label>Género</label>
                        <select
                            className="text-lg w-full"
                            value={genero}
                            onChange={e => setGenero(e.target.value)}
                        >
                            <option value="0">Todos</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                    </div>
                </div>
                
            </div>
            <div className="px-2 h-full overflow-y-auto mt-3 border-b-2 border-gray-600">
            {data}
           
           </div>   
           
        </div>
        <div className="w-full text-lg mt-2 text-center w-1/3 flex flex-row items-center justify-evenly">
        <div className="flex flex-row w-1/6 justify-between">
            <label>Seleccionados: </label>
            <label>{seleccionados}</label>
        </div>
        <button 
            className="text-lg text-white bg-green-600 p-3 rounded-xl"
            onClick={() => guardar()}
        >Descargar</button>
        </div>
    </>)
}