import Menu from './menu.js'
import {useState, useEffect} from 'react'
import LisCateg from './listado_categoria.js'
import LisCaja from './listado_recibo.js'


export default function Listados (props) {
    const [mostrar, setMostrar] = useState('')
    const [socios, setSocios] = useState([])
   
    useEffect (() => {
        function traer_socios() {
            if(props.token!=='') {
                fetch(props.back + 'admin/traer_socios', {
                    method: 'GET',
                    headers: {
                      'Authorization': 'Bearer ' + props.token,
                      'Content-Type': 'application/json' 
                    }
                 })
                .then (response => response.json())
                .then (json => {
                    setSocios(json)
                    setMostrar('Socio')
                })
             }
        }
        traer_socios()
    },[])

    return (<>
        <Menu mostrar={mostrar} setMostrar={setMostrar}/>
        {mostrar==='Socio' ? <LisCateg socios={socios} actividades={props.actividades}/> : ''}
        {mostrar==='Caja' ? <LisCaja back={props.back} token={props.token} socios={socios} actividades={props.actividades}/> : ''}
    </>)
}